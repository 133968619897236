.FullImage1 {
    position: relative;
    max-height: 80vh;
    height: 80vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.FullImage1 video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(8px) brightness(0.6);
    transform: scale(1.1)
}

.FullImage1 > .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 20px 30px;
    text-align: center;
}

.FullImage1 h2 {
    font-size: 50px;
    color: var(--background);
}

.FullImage1 a {
    display: inline-block;
}
.DropdownButton > a {
    position: relative;
}

.DropdownButton:not(:hover) .dropdown-option-container {
    display: none;
}

.DropdownButton span {
    display: block;
    height: 24px;
}

.DropdownButton svg {
    transition: .1s;
    vertical-align: middle;
    fill: var(--text);
    height: 24px;
    width: 24px;
}

.DropdownButton a:hover svg {
    fill: var(--gray);
}

.dropdown-option-container {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 160px;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--background);
    margin-top: 17px;
    border: 1px solid var(--gray);
    border-radius: 0 0 5px 5px;
    border-top: 1px solid var(--background);
}

.DropdownOption {
    text-overflow: '...';
    overflow: hidden;
    white-space: nowrap;
    padding: 5px 0;
}

.DropdownOption a {
    transition: 0.1s;
    color: var(--text);
    text-decoration: none;
    height: 24px;
}

.DropdownOption a:hover {
    color: var(--gray)
}

@media screen and (max-width: 900px) {
    .DropdownButton svg {
        display: none;
    }

    .dropdown-option-container {
        display: block !important;
        border: none;
        position: relative;
        top: 0;
    }

    .DropdownOption a {
        color: #444;
    }

    .DropdownButton.NavButton a {
        height: auto;
    }
}
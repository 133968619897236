.Navbar {
    width: 100%;
    display: block;
    position: sticky;
    height: 60px;
    top: 0;
    z-index: 10;
    background-color: var(--background);
    border-bottom: 1px solid var(--gray);
    padding: 0 10%;
    box-sizing: border-box;
}

.Navbar > .navLinks {
    display: flex;
    float: right;
    height: 100%;
}

@media screen and (max-width:900px) {
    .Navbar > .navLinks {
        display: none;
        position: fixed;
        top:0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--background);
        flex-direction: column;
    }

    .Navbar > .navLinks.visible {
        display: flex;
    }

    .navLinks > * {
        z-index: 1;
        position: relative;
    }
}

.CardPalette1>.container {
    display: grid;
    grid-template:
        'a a b c' 300px
        'a a d e' 300px;
    width: 100%;
    gap: 20px;
}

.CardPalette1>.container>.card1 {
    grid-area: a;
}

.CardPalette1>.container>.card2 {
    grid-area: b;
}

.CardPalette1>.container>.card3 {
    grid-area: c;
}

.CardPalette1>.container>.card4 {
    grid-area: d;
}

.CardPalette1>.container>.card5 {
    grid-area: e;
}

@media screen and (max-width:900px) {
    .CardPalette1 > .container {
        display: grid;
        grid-template:
            'a a' 600px
            'b c' 300px
            'd e' 300px;
        width: 100%;
        gap: 20px;
    }
}
.AnnotatedImage {
    width: 100%;
    display: grid;
}

.AnnotatedImage figure {
    display: block;
    width: 100%;
    margin: 0;
}

.AnnotatedImage figure img {
    width: 100%;
    object-fit: cover;
}

.AnnotatedImage img {
    object-fit: contain;
    object-position: center;
    max-height: 400px;
    display: block;
}

.AnnotatedImage figcaption {
    margin: 10px 0 0 0;
    display: inline-block;
}
.VerticalCard1 {
    border: 1px solid var(--gray);
    border-radius: 8px;
}

.VerticalCard1 .text {
    padding: 10px;
}

.VerticalCard1 > .container > img {
    width: 100%;
    border-radius: 8px 8px 0 0;
}
.InitiativeCard.disabled {
    display: none;
}

.InitiativeCard {
    border-top: 10px solid black;
    padding: 10px 20px;
    display: grid;
}

.InitiativeCard h3 {
    text-align: center;
}

.InitiativeCard a {
    margin: auto 0;
    justify-self: center;
}
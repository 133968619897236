.Tag {
    margin: 0;
    padding: 8px 12px;
    text-align: center;
    width: 100px;
    background-color: var(--accent);
    display: inline-block;
    overflow-x: scroll;
    border-radius: 5px;
}

.Tag.enabled {
    background-color: var(--secondary);
    color: var(--primary);
    transition: .1s;
}
.NavLogo {
    float: left;
    height: 100%;
    padding: 5px 20px;
    box-sizing: border-box;
}

.NavLogo img {
    height: 100%;
}

.NavLogo svg {
    width: 60px;
    height: 50px;
}
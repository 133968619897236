.TitleSlide {
    position: relative;
    height: 40vh;
    background-color: var(--dark-accent);
}

.TitleSlide > h1 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    align-content: center;
    justify-content: center;
    margin: 0;
    z-index: 2;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --primary: #4f7460;
    --secondary: #97b6a3;
    --accent: #d88754;
    --background: #eee;
    --text: #222;
    --gray: #999;
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--background);
    font-family: "Roboto", sans-serif;
}

.content {
    min-height: 100vh;
}

.slide {
    display: block;
    width: 100%;
    margin: 20px auto;
    margin-top: 0;
    max-width: 1200px;
}

.slide.wide {
    max-width: none;
}

.content > div {
    width: 100%;
    overflow: hidden;
}

.content > div:nth-child(2n + 1):not(.uncolored) {
    background-color: var(--accent-bg);
}

h1 {
    font-size: 60px;
    font-weight: 800;
}

h2, .title {
    font-size: 32px;
    font-weight: 600;
}

h3, .subtitle {
    font-size: 24px;
    font-weight: 400;
}

p, .paragraph {
    font-size: 20px;
    font-weight: 300;
    max-width: 800px;
}

h4, .note {
    font-size: 20px;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--text);
}

.observable:not(.observed) {
    opacity: 0;
}

.observable {
    overflow: hidden;
}

.observable obs {
    position: relative;
    top: 100px;
    transition: .2s;
}

.observable.visible obs {
    top: 0;
    opacity: 1;
}

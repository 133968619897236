.ImageCard1 {
    min-height: 275px;
    height: 100%;
    width: 100%;
}

.ImageCard1 > a {
    display: block;
    height: 100%;
    width: 100%;
    filter: brightness(80%);
    transition: 0.5s;
    background-position: center;
    background-repeat: no-repeat;
    backdrop-filter: blur(5px);
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.ImageCard1 > a:hover {
    filter: brightness(100%);
}

.ImageCard1 > .container {
    position: relative;
}

.ImageCard1 .text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    padding: 10px 20px;
}

.ImageCard1 .text * {
    margin: 0;
    color: #fafafa;
}
.ContactUs {
    margin: 15px auto;
    padding: 10px;
    box-sizing: border-box;
}

.ContactUs form {
    display: grid;
    grid-template:
        "a b" 40px
        "a c" 40px
        "a d" 40px
        "a e" auto
        "a f" 50px;
    gap: 20px;
    height: 600px;
    width: 100%;
    font-size: 16px;
}

.ContactUs .maps-embed {
    background-color: var(--accent-bg);
    width: 100%;
    height: 100%;
    grid-area: a;
    border: 0;
}

.ContactUs .email {
    grid-area: b;
}

.ContactUs .name {
    grid-area: c;
}

.ContactUs .subject {
    grid-area: d;
}

.ContactUs .message {
    grid-area: e;
    resize: none;
}

.ContactUs .submit {
    grid-area: f;
}

.ContactUs form input[type=submit] {
    background-color: var(--primary);
    border: 0;
    padding: 12px 22px;
    color: var(--background);
    margin-top: 10px;
    display: block;
    font-size: 16px;
    transition: 0.3s;
}

.ContactUs form input[type=submit]:hover {
    background-color: var(--secondary);
    color: var(--text);
}
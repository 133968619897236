.Hamburger {
    height: 100%;
    margin: auto 0;
    float: right;
    z-index: 10;
    position: relative;
}

.Hamburger svg {
    width: 50px;
    height: 100%;
}

@media screen and (min-width:900px) {
    .Hamburger {
        display: none;
    }
}
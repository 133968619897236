.LinkButton1 {
    padding: 10px 20px;
    text-decoration: none;
    color: var(--background);
    font-size: 24px;
    background-color: var(--primary);
    transition: 0.3s;
    display: inline-block;
}

.LinkButton1:hover {
    background-color: var(--secondary);
    color: var(--text)
}
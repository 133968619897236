.AttributionTable img {
    max-width: 300px;
}

.AttributionTable table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.AttributionTable th, .AttributionTable td {
    border: 1px solid black;
    padding: 10px;
}
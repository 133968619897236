.Footer {
    width: 100%;
    padding: 10px 10%;
    box-sizing: border-box;
    border-top: 1px solid var(--gray);
}

.Footer>.container {
    width: 100%;
    display: flex;
    gap: 10px;
}

.Footer svg {
    width: 300px;
    height: 200px;
}

.Footer>.container>.child {
    flex: 1;
}

.Footer h2 {
    font-size: 20px;
}

.Footer ul {
    list-style: none;
    padding: 0;
}

.Footer li {
    padding: 2px 2px 0 10px;
}

.Footer a {
    color: var(--text);
    text-decoration: none;
    font-size: 20px;
}

.Footer form input[type=email] {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--gray);
    display: block;
    font-size: 16px;
    box-sizing: border-box;
}

.Footer form input[type=submit] {
    background-color: var(--primary);
    border: 0;
    padding: 12px 22px;
    color: var(--background);
    margin-top: 10px;
    display: block;
    font-size: 16px;
    transition: 0.3s;
}

.Footer form input[type=submit]:hover {
    background-color: var(--secondary);
    color: var(--text);
}

.Footer ::placeholder,
.Footer ::-moz-placeholder,
.Footer :-moz-placeholder,
.Footer ::-webkit-input-placeholder,
.Footer ::-ms-value {
    font-family: "Roboto", sans-serif;
}

@media screen and (max-width:900px) {
    .Footer .child:first-child {
        display: none;
    }
}
.Testimonial {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

.Testimonial .img {
    aspect-ratio: 1;
    float: left;
    margin-right: 10px;
    height: 200px;
    position: relative;
}

.Testimonial img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin-right: 20px;
}

.Testimonial .txt {
    padding: 10px 30px;
}

.Testimonial::after {
    content: "";
    display: block;
    clear: both;
}
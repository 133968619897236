.tags {
    padding: 10px 30px;
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    justify-content: space-around;
    overflow-x: scroll;
    margin: 10px;
}

.initiatives {
    display: grid;
    grid-template-columns: auto auto;
    gap: 60px 40px;
}

@media screen and (max-width:600px) {
    .initiatives {
        grid-template-columns: auto;
    }
}
.SourceCard {
    background-image: var(--bgImg);
    position: relative;
    height: 480px;
}

.SourceCard .text {
    padding: 10px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 10px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    background-color: #00000070;
    
}

.SourceCard .text > * {
    color: var(--background);
    margin: 8px 0 8px 0;
}
.CardPalette2>.container {
    display: grid;
    grid-template-areas:
        'a a a'
        'b c d';
    width: 100%;
    grid-template-rows: 300px 300px;
    gap: 20px;
}

.CardPalette2>.container>.card1 {
    grid-area: a;
}

.CardPalette2>.container>.card2 {
    grid-area: b;
}

.CardPalette2>.container>.card3 {
    grid-area: c;
}

.CardPalette2>.container>.card4 {
    grid-area: d;
}
.TitledCards1 {
    min-height: 50vh;
}

.TitledCards1 > .container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
    gap: 20px;
}

@media screen and (max-width:900px) {
    .TitledCards1 > .container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
.NavButton {
    padding: 0 20px;
    display: flex;
    text-decoration: none;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
}

.NavButton a {
    transition: 0.1s;
    color: var(--text);
    text-decoration: none;
    height: 24px;
}

.NavButton a:hover {
    color: var(--gray);
}